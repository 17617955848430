import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Main from '../components/Main'
import CompetitionGrid from '../components/CompetitionGrid'

export default function IndexPage({ data: { competitions } }) {
  return (
    <Layout>
      <SEO title="Home" />
      <Main>
        <CompetitionGrid competitions={competitions.nodes} />
      </Main>
    </Layout>
  )
}

export const query = graphql`
  query {
    competitions: allCompetition {
      nodes {
        id
        name
      }
    }
  }
`
